<template lang="html">
    <section class="interviews">
        <section class="container">
            <template v-if="interviews">
                <section class="container-grid">
                    <div class="grid-big">
                        <template v-for="(interview, key, index) in interviews" :key="key">
                            <interviewFeatured v-if="index == 0" :dataContent="interview"></interviewFeatured>
                        </template>
                    </div>
                    <div class="grid-small">
                        <template v-for="(interview, key, index) in interviews" :key="key">
                            <newVerticalImg v-if="index == 1" :dataContent="interview"></newVerticalImg>
                        </template>
                    </div>
                </section>
                <section class="grid-3">
                    <template v-for="(interview, key, index) in interviews" :key="key">
                        <newVerticalImg v-if="index > 1" :dataContent="interview"></newVerticalImg>
                    </template>
                </section>
                <paginator :pageActual="page" @changed="changePages" :totalItems="InterviewsMaxItems" @clicked="changePages"></paginator>
            </template>
            <div v-else class="container-noResult">
                <p class="title-dest-underline">No hay resultados</p>
            </div>
        </section>
    </section>
</template>

<script lang="js">
import {
    mapGetters,
    mapActions,
    mapMutations
} from 'vuex';
import store from '@/store';
import interviewFeatured from "@/components/interviews/interview-featured.vue";
import newVerticalImg from "@/components/news/new-featured-vertical-img.vue";
import paginator from "@/components/paginator.vue";
import utils from "@/utils/utils.js";
export default {
    name: 'interviews',
    components: {
        interviewFeatured,
        newVerticalImg,
        paginator
    },
    props: ['filterTime'],
    methods: {
        ...mapActions({
            loadInterviews: "contents/loadInterviews",
        }),
        ...mapMutations({
            setPageInterviews: 'contents/setPageInterviews',

        }),
        changePages(item) {
            this.setPageInterviews(item)
            this.loadInterviews({
                page: this.page,
            });
            utils.LoaderScrollToTop();
        }
    },
    computed: {
        ...mapGetters({
            getPagesInterviews: "contents/getPagesInterviews",
            loading: 'contents/getLoading',
            page: 'contents/getinterviewsViewPage',
            theme: 'contents/getinterviewsLastFiltersTheme',
            zone: 'contents/getinterviewsLastFiltersZone'
        }),
        interviews: function () {
            return store.getters['contents/getInterviews'](this.page)
        },
        InterviewsMaxItems() {
            return this.getPagesInterviews();
        }
    },
    mounted() {
        this.loadInterviews({ page: 1});
    },
    watch: {
        filterTime: function (newVal, oldVal) {
            this.loadInterviews({ page: 1});
        }
    }
}
</script>

<style scoped lang="scss">

 .interviews{
        .new-featured-vertical-img,
        .new-full-img .info-box{
            border: 0;
        }
    }
</style>