<template lang="html">
    <section class="interview-featured" v-if="dataContent">
        <router-link :to="{
        name: 'page-entry',
        params: {
          typeContent: this.dataContent.type,
          slug: this.dataContent.slug,
        },
      }" class="content-new" title="Enlace a la entrevista">
            <img :src="dataContent.image.url" :alt="dataContent.image_footer" class="img-new" />
            <div class="info-box">
                <p class="title">Entrevista a...</p>
                <p class="subtitle">{{dataContent.interviewed}}</p>
                <p class="charge">
                    {{dataContent.interviewed_job}}
                </p>
            </div>
            <div class="more-info">
                <h2 class="entrance">{{dataContent.name}}</h2>
                <p class="box-dates">
                    <span class="date-text"> {{ currentDateTime(dataContent.publish_date) }} </span>
                    <span class="author" v-if="dataContent.source">{{dataContent.source}}</span>
                    <template v-for="theme in dataContent.themes" :key="theme.id">
                        <span class="category-tag">{{ theme.name }}</span>
                    </template>
                    <span class="category-location" v-if="dataContent.zone">{{
                        dataContent.zone.name
                        }}</span>
                </p>

                <p>
                    {{dataContent.header}}
                </p>
            </div>
        </router-link>

    </section>
</template>

<script lang="js">
    import moment from 'moment'
    export default {
        name: 'interview-featured',
        props: ['dataContent'],
        mounted() {

        },
        data() {
            return {

            }
        },
        methods: {
            currentDateTime(date) {
                return moment(date).locale('es').format('L')
            },
        },
        computed: {
            typeContent() {
                switch (this.dataContent.type) {
                    case 'entry':
                        return 'noticia'
                    case 'opinion':
                        return 'opinion'
                    case 'interview':
                        return 'entrevista'
                    case 'coverage':
                        return 'reportaje'
                    case 'event':
                        return 'evento'
                    case 'media':
                        return 'multimedia'
                    default:
                        return 'noticia'
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "@/styles/colours.scss";

    .interview-featured {
        background: $blueLight;
        border-radius: 13px;
        cursor: pointer;

        .content-new {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            text-decoration: none;

            .img-new {
                width: 50%;
                height: 240px;
                object-fit: cover;
                border-radius: 13px 0 0 0;
            }

            .info-box {
                width: 50%;
                padding: 30px;

                .title {
                    font-size: 45px;
                    line-height: 1.2em;
                    margin-bottom: 5px;
                }

                .subtitle {
                    font-size: 30px;
                    font-weight: 700;
                    line-height: 1.2em;
                    color: $blue;
                    font-family: 'Frank Ruhl Libre', serif;
                }

                .charge {
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 1.2em;
                    color: $blue;
                    font-family: 'Frank Ruhl Libre', serif;
                }
            }
        }

        .more-info {
            padding: 30px;

            .entrance {
                font-size: 30px;
                font-weight: 700;
                line-height: 1.2em;
                margin-bottom: 8px;
                font-family: "Frank Ruhl Libre", serif;
            }
        }
    }

    @media screen and (max-width:845px) {
        .interview-featured .content-new .info-box .title {
            font-size: 30px;
        }

        .interview-featured .content-new .info-box .subtitle {
            font-size: 20px;
            margin-bottom: 10px;
        }

        .interview-featured .more-info {
            padding: 20px;
            width: 100%;
        }

        .interview-featured .more-info .entrance {
            font-size: 18px;
            margin-bottom: 5px;
        }

        .interview-featured {
            margin-bottom: 20px;
        }
    }

    @media screen and (max-width:750px) {
        .interview-featured .content-new .img-new {
            width: 100%;
            height: 235px;
            border-radius: 13px 13px 0 0;
        }

        .interview-featured .content-new .info-box {
            width: 100%;
            padding: 20px 20px 0 20px;
        }

        .interview-featured .content-new .info-box .title {
            font-size: 25px;
        }

        .interview-featured .content-new .info-box .subtitle {
            font-size: 20px;
            margin-bottom: 5px;
        }
    }
</style>